:root {
  --mainDark: #343a40;
  --mainGrey: rgba(109, 109, 109);
  --mainLightGrey: rgba(172, 172, 172);
  --mainWhite: #fff;
  --mainBlue: #007bff;
  
  --gradient-color: linear-gradient(90deg, rgb(44, 126, 194) 0%, #171f97 100%);
  --gradient-color-second: linear-gradient(90deg, rgb(55, 218, 182) 0%, rgb(24, 131, 108) 100%);
  --box-shadow: 0px 10px 30px rgba(57, 56, 61, 0.205);

  --roboto: 'Roboto', serif;
}

body{
  background-color: rgb(245, 245, 245) !important; 
}

.navbar{
  background-color: var(--mainDark);
}


.navbar-toggler:focus{
  outline: none;
}

.navbar-toggler{
  border: none;
}

@media (max-width: 390px){
  .name{
    font-size: 4rem !important;
  }
  /* CONTACT PAGE */
  .emailInfo{
    font-size: 25px !important;
  }

}


/** Small devices (landscape phones, up to 576px) **/
@media (max-width: 576px) { 

  .rezDlBtn{
    text-align: center;
  }
  .list-inline{
    flex-direction: column;
  }

  .grid .our-project > .title h4{
    font: normal 700 17px/12px var(--roboto) !important;
}
.grid{
  text-align: center;
}
.our-project{
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;
  text-align: center;
}
.projectOne{
  text-align: center;
}

  /* PORTFOLIO PAGE */
  .projectRow{
    text-align: center;
  }


  /* CONTACT PAGE */
  .bottomInfo{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
 }

/*Medium devices (tablets, up to 768px)  */
@media (max-width: 768px) {
  .cartoon-image{
    width: 75%;
  }
  .about-image{
    width: 75%;
  } 
  .aboutRow{
    display: flex;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  .about-intro{
    display: flex;
    flex-direction:column;
    
  }

  /* PORTFOLIO PAGE */
  .projectRow{
    text-align: center;
  }
  /* CONTACT PAGE */
  .phoneInfo{
    font-size: 70px;
  }
  .emailInfo{
    font-size: 45px;
  }
 }

 /* Large devices (desktops, up to 992px) */
@media (max-width: 992px) { 

  /* HOME PAGE */

  .about-intro{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .rezDlBtn{
  text-align: center;
  }
  .projectOne{
    text-align: center; 
  }
  .projectTwo{
      text-align: center; 
  }
  .projectThree{
    text-align: center; 
  }
  /* ABOUT PAGE */
  .headshot-image{
    width: 50%;
    margin-left: 3%;
  }

  .expressionRow{
    text-align: center;
  }
  /* .expression-image{
    width:50%; 
    text-align: center;
    margin-left: 23%;
  } */
  .expression-container{
    display: flex;
    flex-direction: column-reverse;
  }
  .lambdaText{
    margin-left: 10%;
  }
  .lambda{
    width: 50%;
  }
  .lambda-image{
    text-align: center;
  }



/* PORTFOLIO PAGE */
.projectRow{
  display: flex;
  justify-content: center;
}

 }

/* Extra large devices (large desktops, up to1200px) */
@media (max-width: 1200px) { 

 }

